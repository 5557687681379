import ToastComponent from '~/components/ui/ToastComponent.vue';
import { useToast } from 'vue-toastification';
import { CSRF_COOKIE_KEY, CSRF_HEADER_KEY } from '~/constants/csrfKeys';

export async function useCustomFetchClient<T>(
  url: string,
  options: any = {},
  version: string = '1',
  skipError = false,
) {
  try {
    const { apiUrl } = useRuntimeConfig().public;
    const requestHeaders: any = useRequestHeaders(['cookie']);

    const csrfToken = useCookie(CSRF_COOKIE_KEY).value;

    if (csrfToken) {
      requestHeaders[CSRF_HEADER_KEY] = csrfToken;
    }

    requestHeaders.accept = 'application/json';

    const data = await $fetch<T>(url, {
      baseURL: apiUrl + version,
      credentials: 'include',
      headers: requestHeaders,
      mode: 'cors',
      ...options,
    });

    if (data) return data;
  } catch (e: any) {
    if (e && e.statusCode) {
      console.error(e);

      if (import.meta.client && [401, 403, 500, 502].includes(e.statusCode) && e.data?.message) {
        const toast = useToast();
        toast.error({ component: ToastComponent, props: { text: e.data.message } });
      }

      if (String(e.statusCode) === '404' && !skipError) {
        navigateTo({ path: '/page-not-found', replace: true });
      }
    }
  }
}
